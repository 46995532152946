import React from 'react';

const ServiceButton = ({ icon, label, url }) => (
    <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-xl shadow-md hover:shadow-lg transition-all duration-200"
    >
        <div className="text-3xl mb-2">{icon}</div>
        <span className="text-gray-800 dark:text-white font-medium">{label}</span>
    </a>
);

const ServiceButtons = () => {
    const services = [
        {
            icon: '☁️',
            label: 'Cloud',
            url: '/cloud'
        },
        {
            icon: '📧',
            label: 'Mail',
            url: '/mail'
        },
        {
            icon: '🎫',
            label: 'Support GLPI',
            url: '/support'
        },
        {
            icon: '🎣',
            label: 'GoPhish',
            url: 'http://161.97.132.115:3333/'
        }
    ];

    return (
        <div className="mb-8 p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
            <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Services Rapides</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {services.map((service, index) => (
                    <ServiceButton
                        key={index}
                        icon={service.icon}
                        label={service.label}
                        url={service.url}
                    />
                ))}
            </div>
        </div>
    );
};

export default ServiceButtons;
