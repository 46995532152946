import React, { useState, useEffect } from 'react';

const ReadingProgress = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const calculateProgress = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight - windowHeight;
            const scrollTop = window.scrollY;
            const progress = (scrollTop / documentHeight) * 100;
            setProgress(Math.min(100, Math.max(0, progress)));
        };

        window.addEventListener('scroll', calculateProgress);
        calculateProgress();

        return () => window.removeEventListener('scroll', calculateProgress);
    }, []);

    return (
        <div className="fixed top-0 left-0 w-full h-1 z-50">
            <div
                className="h-full bg-blue-500 transition-all duration-300"
                style={{ width: `${progress}%` }}
            />
        </div>
    );
};

export default ReadingProgress;
