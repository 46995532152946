import React, { useState } from 'react';

const getTagStyle = (label) => {
    const styles = {
        'cisco packet tracer': 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900 dark:text-indigo-300',
        'réseau': 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300',
        'infrastructure': 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300',
        'sécurité': 'bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300',
        'firewall': 'bg-orange-100 text-orange-700 dark:bg-orange-900 dark:text-orange-300',
        'vpn': 'bg-yellow-100 text-yellow-700 dark:bg-yellow-900 dark:text-yellow-300',
        'active directory': 'bg-purple-100 text-purple-700 dark:bg-purple-900 dark:text-purple-300',
        'glpi': 'bg-pink-100 text-pink-700 dark:bg-pink-900 dark:text-pink-300',
        'windows server': 'bg-cyan-100 text-cyan-700 dark:bg-cyan-900 dark:text-cyan-300',
        'gophish': 'bg-teal-100 text-teal-700 dark:bg-teal-900 dark:text-teal-300',
        'phishing': 'bg-rose-100 text-rose-700 dark:bg-rose-900 dark:text-rose-300',
        'cyber-risques': 'bg-amber-100 text-amber-700 dark:bg-amber-900 dark:text-amber-300',
        'serveur': 'bg-sky-100 text-sky-700 dark:bg-sky-900 dark:text-sky-300',
        'linux': 'bg-lime-100 text-lime-700 dark:bg-lime-900 dark:text-lime-300'
    };

    return styles[label.toLowerCase()] || 'bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300';
};

const Tag = ({ label }) => (
    <span className={`inline-block ${getTagStyle(label)} text-xs px-2 py-1 rounded mr-2 mb-2`}>
        #{label}
    </span>
);

const EnhancedImage = ({ src, alt, caption, tags = [] }) => {
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <div className="relative max-w-2xl my-4">
            <div className="relative overflow-hidden rounded-lg">
                {loading && (
                    <div className="absolute inset-0 bg-gray-200 dark:bg-gray-700 animate-pulse" />
                )}
                <img
                    src={src}
                    alt={alt}
                    className={`w-full h-auto transition-opacity duration-300 ${
                        loading ? 'opacity-0' : 'opacity-100'
                    }`}
                    onLoad={handleImageLoad}
                />
            </div>
            
            {tags && tags.length > 0 && (
                <div className="mt-2">
                    {tags.map((tag, index) => (
                        <Tag key={index} label={tag} />
                    ))}
                </div>
            )}
            
            {caption && (
                <p className="text-sm text-gray-600 dark:text-gray-400 mt-2 italic">
                    {caption}
                </p>
            )}
        </div>
    );
};

export default EnhancedImage;
