import React, { useEffect, useRef } from 'react';

const AnimatedSection = ({ children, id }) => {
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-fade-in');
                }
            },
            {
                threshold: 0.1,
                rootMargin: '-50px'
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section
            ref={sectionRef}
            id={id}
            className="transition-all duration-700 ease-out"
        >
            {children}
        </section>
    );
};

export default AnimatedSection;
