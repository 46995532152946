import React, { useState } from 'react';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        const results = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6');
        const matches = [];

        results.forEach(element => {
            if (element.textContent.toLowerCase().includes(term.toLowerCase())) {
                matches.push({
                    text: element.textContent,
                    element: element
                });
            }
        });

        setSearchResults(matches);
    };

    const handleResultClick = (element) => {
        element.scrollIntoView({ behavior: 'smooth' });
        setSearchResults([]);
        setSearchTerm('');
    };

    return (
        <div className="relative">
            {searchResults.length > 0 && (
                <div className="absolute bottom-full mb-2 w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg max-h-60 overflow-y-auto z-50">
                    {searchResults.map((result, index) => (
                        <div
                            key={index}
                            onClick={() => handleResultClick(result.element)}
                            className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer text-sm text-gray-700 dark:text-gray-200"
                        >
                            {result.text.slice(0, 100)}...
                        </div>
                    ))}
                </div>
            )}
            
            <div className="relative flex items-center">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg 
                        className="w-4 h-4 text-gray-400 dark:text-gray-500" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
                        />
                    </svg>
                </div>
                <input
                    type="search"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Rechercher..."
                    className="
                        w-full pl-10 pr-4 py-1.5
                        text-sm text-gray-700 dark:text-gray-200
                        bg-gray-50 dark:bg-gray-800
                        border border-gray-200 dark:border-gray-700
                        rounded-lg
                        focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400
                        focus:border-transparent
                        placeholder-gray-400 dark:placeholder-gray-500
                        transition-all duration-200
                    "
                />
                {searchTerm && (
                    <button
                        onClick={() => {
                            setSearchTerm('');
                            setSearchResults([]);
                        }}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
                    >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
};

export default SearchBar;
