import React from 'react';
import { useTheme } from '../context/ThemeContext';

const DarkModeToggle = () => {
    const { darkMode, toggleDarkMode } = useTheme();

    return (
        <button
            onClick={toggleDarkMode}
            className={`
                relative w-12 h-6 rounded-full
                ${darkMode ? 'bg-blue-600' : 'bg-yellow-400'}
                transition-colors duration-300 ease-in-out
                focus:outline-none focus:ring-2 focus:ring-offset-2
                ${darkMode ? 'focus:ring-blue-400' : 'focus:ring-yellow-300'}
            `}
            aria-label="Toggle dark mode"
        >
            <div
                className={`
                    absolute left-0.5 top-0.5
                    w-5 h-5 rounded-full
                    transform transition-transform duration-300 ease-in-out
                    flex items-center justify-center
                    ${darkMode ? 'translate-x-6 bg-white' : 'translate-x-0 bg-white'}
                `}
            >
                {darkMode ? (
                    <svg
                        className="w-3.5 h-3.5 text-blue-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                    </svg>
                ) : (
                    <svg
                        className="w-3.5 h-3.5 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                            clipRule="evenodd"
                        />
                    </svg>
                )}
            </div>
            {darkMode && (
                <>
                    <span className="absolute w-1 h-1 bg-white rounded-full top-1 left-2 animate-twinkle" />
                    <span className="absolute w-1 h-1 bg-white rounded-full top-3 right-2 animate-twinkle delay-75" />
                    <span className="absolute w-0.5 h-0.5 bg-white rounded-full bottom-1 right-3 animate-twinkle delay-150" />
                </>
            )}
        </button>
    );
};

export default DarkModeToggle;
