import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ReadingProgress from './components/ReadingProgress';
import ScrollToTop from './components/ScrollToTop';
import AnimatedSection from './components/AnimatedSection';
import EnhancedImage from './components/EnhancedImage';
import ServiceButtons from './components/ServiceButtons';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import './App.css';
import Introduction from './pages/Introduction';
import Infrastructure from './pages/Infrastructure';
import Security from './pages/Security';
import UserManagement from './pages/UserManagement';
import RiskAssessment from './pages/RiskAssessment';
import Conclusion from './pages/Conclusion';
import { FaRegCopy } from 'react-icons/fa';
import './responsive.css';

const AppContent = () => {
    const { darkMode, toggleDarkMode } = useTheme();
    const [modalImage, setModalImage] = React.useState(null);
    const [showInfo, setShowInfo] = useState(false);

    const openModal = (src) => {
        setModalImage(src);
    };

    const closeModal = () => {
        setModalImage(null);
    };

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            const sidebarLinks = document.querySelectorAll(".sidebar a");

            let currentActiveIndex = -1;

            sections.forEach((section, index) => {
                const rect = section.getBoundingClientRect();
                const sectionTop = window.scrollY + rect.top;
                const sectionBottom = sectionTop + section.offsetHeight;

                if (window.scrollY >= sectionTop - 50 && window.scrollY < sectionBottom - 50) {
                    currentActiveIndex = index;
                }
            });

            // Adjust the bottom of the page check with a small buffer
            if (Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 5) {
                currentActiveIndex = sections.length - 1; // Set to the last section (Conclusion)
            }

            sidebarLinks.forEach((link, index) => {
                if (index === currentActiveIndex) {
                    link.classList.add('active');
                } else {
                    link.classList.remove('active');
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            alert('Code copié dans le presse-papiers!');
        }).catch(err => {
            console.error('Erreur lors de la copie du code: ', err);
        });
    };

    return (
        <Router>
            <div className={`flex min-h-screen ${darkMode ? 'dark' : ''}`}>
                <ReadingProgress />
                <ScrollToTop />
                <Sidebar />
                <div className="main-content transition-colors duration-200 dark:bg-gray-900 dark:text-white">
                    <div className="p-4">
                        <ServiceButtons />
                        <Routes>
                            <Route path="/" element={
                                <>
                                    <AnimatedSection id="introduction">
                                        <h1 className="text-4xl font-bold mb-2">Introduction Générale</h1>
                                        <p>Bienvenue dans le wiki de l'infrastructure informatique d'entreprise. Ce document interactif est conçu pour fournir une vue d'ensemble complète des projets d'infrastructure informatique et de cybersécurité. Notre objectif est de centraliser toutes les informations pertinentes, facilitant ainsi la compréhension et la gestion des systèmes complexes qui soutiennent les opérations de l'entreprise.</p>
                                        <h2 className="text-2xl font-semibold mb-4">Objectifs du Wiki</h2>
                                        <ul className="list-disc pl-5 mb-4">
                                            <li><strong>Documentation Complète</strong>: Offrir une documentation détaillée sur chaque aspect de l'infrastructure informatique, y compris la mise en place, la sécurisation, et la gestion des ressources.</li>
                                            <li><strong>Accessibilité</strong>: Rendre l'information facilement accessible à tous les membres de l'équipe, indépendamment de leur niveau de compétence technique.</li>
                                            <li><strong>Collaboration</strong>: Encourager la collaboration et le partage des connaissances au sein de l'organisation.</li>
                                        </ul>
                                        <h2 className="text-2xl font-semibold mb-4">Fonctionnalités du Projet d'Infrastructure</h2>
                                        <table className="table-auto w-full mb-4">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2">Fonctionnalité</th>
                                                    <th className="px-4 py-2">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border px-4 py-2">Mise en place de l'infrastructure</td>
                                                    <td className="border px-4 py-2">Configuration et déploiement des réseaux et serveurs.</td>
                                                </tr>
                                                <tr className="bg-gray-100">
                                                    <td className="border px-4 py-2">Sécurisation de l'entreprise</td>
                                                    <td className="border px-4 py-2">Mise en œuvre de pare-feu et de VPN pour protéger les données.</td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2">Gestion des utilisateurs et des ressources</td>
                                                    <td className="border px-4 py-2">Déploiement d'Active Directory et configuration des serveurs de messagerie.</td>
                                                </tr>
                                                <tr className="bg-gray-100">
                                                    <td className="border px-4 py-2">Évaluation des cyber-risques</td>
                                                    <td className="border px-4 py-2">Simulation d'attaques et analyse de sécurité pour identifier les vulnérabilités.</td>
                                                </tr>
                                                <tr>
                                                    <td className="border px-4 py-2">Documentation complète</td>
                                                    <td className="border px-4 py-2">Documentation détaillée des processus et configurations techniques.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h2 className="text-2xl font-semibold mb-4">Structure du Wiki</h2>
                                        <p>Le wiki est organisé en plusieurs sections, chacune dédiée à un aspect spécifique de l'infrastructure informatique. Chaque section contient des textes descriptifs, des extraits de code, et des images illustratives pour aider à clarifier les concepts complexes.</p>
                                        <p>Nous espérons que ce wiki deviendra un outil précieux pour tous ceux qui participent à la gestion et à l'amélioration de notre infrastructure informatique d'entreprise.</p>
                                        <div className="note">
                                            <h3 className="text-lg font-semibold mb-2">Note</h3>
                                            <p>Ceci est une note pour ajouter des commentaires ou des explications supplémentaires.</p>
                                        </div>
                                        <EnhancedImage
                                            src="https://via.placeholder.com/600x400"
                                            alt="Description"
                                            caption="Légende de l'image"
                                            tags={['Infrastructure', 'Réseau', 'Serveur']}
                                        />
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="introduction-2">
                                        <h1 className="text-4xl font-bold mb-2">Projet Tuteuré</h1>
                                        <h2 className="text-xl mb-4">B3 Groupe Rayan AMEUR, Kirk CANTALOUBE, Thibaut GENET, Massil ABBA, Alexis TCHOULEV</h2>
                                        <pre className="bg-gray-200 p-4 rounded mb-6 shadow relative">
                                            <button
                                                onClick={() => handleCopy(`Ce projet vise à documenter la mise en place et l'évaluation d'une infrastructure informatique complète d'entreprise.`)}
                                                className="absolute top-2 right-2 bg-gray-500 text-white p-1 rounded hover:bg-gray-600 flex items-center"
                                            >
                                                <FaRegCopy />
                                            </button>
                                            <code>
                                                {`Ce projet vise à documenter la mise en place et l'évaluation d'une infrastructure informatique complète d'entreprise.`}
                                            </code>
                                        </pre>
                                        <EnhancedImage
                                            src="https://via.placeholder.com/600x400"
                                            alt="Diagramme de l'infrastructure"
                                            caption="Légende de l'image"
                                            tags={['Infrastructure', 'Réseau', 'Serveur']}
                                        />
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                                            En savoir plus
                                        </button>
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="infrastructure">
                                        <h1>Mise en Place de l'Infrastructure</h1>
                                        <p>La mise en place de l'infrastructure inclut la simulation réseau et la configuration du routage.</p>
                                        <pre className="bg-gray-200 p-4 rounded mb-6 shadow relative">
                                            <button
                                                onClick={() => handleCopy(`router.configure({\n    isStatic: true,\n    dynamic: false\n});`)}
                                                className="absolute top-2 right-2 bg-gray-500 text-white p-1 rounded hover:bg-gray-600 flex items-center"
                                            >
                                                <FaRegCopy />
                                            </button>
                                            <code>
                                                {`router.configure({
    isStatic: true,
    dynamic: false
});`}
                                            </code>
                                        </pre>
                                        <EnhancedImage
                                            src="https://via.placeholder.com/600x400"
                                            alt="Simulation réseau"
                                            caption="Simulation d'un réseau d'entreprise"
                                            tags={['Cisco Packet Tracer', 'Réseau', 'Infrastructure']}
                                        />
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="security">
                                        <h1>Sécurisation de l'Entreprise</h1>
                                        <p>Placeholder text for Sécurisation de l'Entreprise. Add your content here.</p>
                                        <img src="https://via.placeholder.com/600x400" alt="Sécurisation Image" />
                                        <pre className="bg-gray-200 p-4 rounded mb-6 shadow relative">
                                            <button
                                                onClick={() => handleCopy(`firewall.setRules(["ALLOW ALL", "DENY 192.168.1.1"]);`)}
                                                className="absolute top-2 right-2 bg-gray-500 text-white p-1 rounded hover:bg-gray-600 flex items-center"
                                            >
                                                <FaRegCopy />
                                            </button>
                                            <code>
                                                {`firewall.setRules(["ALLOW ALL", "DENY 192.168.1.1"]);`}
                                            </code>
                                        </pre>
                                        <EnhancedImage
                                            src="https://via.placeholder.com/600x400"
                                            alt="Configuration du firewall"
                                            caption="Configuration du pare-feu d'entreprise"
                                            tags={['GoPhish', 'Sécurité', 'Firewall', 'VPN']}
                                        />
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="user-management">
                                        <h1>Gestion des Utilisateurs et des Ressources</h1>
                                        <p>Placeholder text for Gestion des Utilisateurs et des Ressources. Add your content here.</p>
                                        <img src="https://via.placeholder.com/600x400" alt="Gestion Image" />
                                        <pre className="bg-gray-200 p-4 rounded mb-6 shadow relative">
                                            <button
                                                onClick={() => handleCopy(`user.create({\n    name: "John Doe",\n    role: "Admin"\n});`)}
                                                className="absolute top-2 right-2 bg-gray-500 text-white p-1 rounded hover:bg-gray-600 flex items-center"
                                            >
                                                <FaRegCopy />
                                            </button>
                                            <code>
                                                {`user.create({
    name: "John Doe",
    role: "Admin"
});`}
                                            </code>
                                        </pre>
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="risk-assessment">
                                        <h1>Évaluation des Cyber-Risques</h1>
                                        <p>L'évaluation des risques inclut la simulation d'attaques et l'analyse de la sécurité.</p>
                                        <pre className="bg-gray-200 p-4 rounded mb-6 shadow relative">
                                            <button
                                                onClick={() => handleCopy(`attack.simulate({\n    type: "phishing",\n    target: "user@example.com"\n});`)}
                                                className="absolute top-2 right-2 bg-gray-500 text-white p-1 rounded hover:bg-gray-600 flex items-center"
                                            >
                                                <FaRegCopy />
                                            </button>
                                            <code>
                                                {`attack.simulate({
    type: "phishing",
    target: "user@example.com"
});`}
                                            </code>
                                        </pre>
                                        <EnhancedImage
                                            src="https://via.placeholder.com/600x400"
                                            alt="Évaluation des risques"
                                            caption="Évaluation des risques de sécurité"
                                            tags={['Phishing', 'Sécurité', 'Cyber-risques']}
                                        />
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="tools">
                                        <h1>Outils Déployés</h1>
                                        <p>Voici un aperçu des outils que nous utilisons pour améliorer notre productivité et sécurité.</p>
                                        <table className="table-auto border-collapse border border-gray-400">
                                            <thead>
                                                <tr>
                                                    <th className="border border-gray-300 px-4 py-2">Outil</th>
                                                    <th className="border border-gray-300 px-4 py-2">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border border-gray-300 px-4 py-2">GLPI</td>
                                                    <td className="border border-gray-300 px-4 py-2">Outil de gestion des services informatiques.</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-gray-300 px-4 py-2">GoPhish</td>
                                                    <td className="border border-gray-300 px-4 py-2">Plateforme de simulation d'attaques de phishing.</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-gray-300 px-4 py-2">NextCloud</td>
                                                    <td className="border border-gray-300 px-4 py-2">Solution de stockage et de partage de fichiers.</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-gray-300 px-4 py-2">RainLoop</td>
                                                    <td className="border border-gray-300 px-4 py-2">Client webmail léger et moderne.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">En savoir plus</button>
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="conclusion">
                                        <h1>Conclusion</h1>
                                        <p>La conclusion résume les risques identifiés et propose des recommandations.</p>
                                        <pre className="bg-gray-200 p-4 rounded mb-6 shadow relative">
                                            <button
                                                onClick={() => handleCopy(`const recommendations = [\n    \"Améliorer la formation des employés\",\n    \"Mettre à jour les systèmes régulièrement\"\n];`)}
                                                className="absolute top-2 right-2 bg-gray-500 text-white p-1 rounded hover:bg-gray-600 flex items-center"
                                            >
                                                <FaRegCopy />
                                            </button>
                                            <code>
                                                {`const recommendations = [
    "Améliorer la formation des employés",
    "Mettre à jour les systèmes régulièrement"
];`}
                                            </code>
                                        </pre>
                                        <EnhancedImage
                                            src="https://via.placeholder.com/600x400"
                                            alt="Conclusion du projet"
                                            caption="Conclusion du projet"
                                            tags={['Conclusion', 'Sécurité']}
                                        />
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                    <AnimatedSection id="final-remarks">
                                        <h1>Remarques finales</h1>
                                        <p>Merci de votre attention.</p>
                                    </AnimatedSection>
                                    <hr className="border-t-2 border-gray-300 my-8" />
                                </>
                            } />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
};

const App = () => {
    return (
        <ThemeProvider>
            <AppContent />
        </ThemeProvider>
    );
};

export default App;
