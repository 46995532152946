import React, { useState } from 'react';
import { Link } from 'react-scroll';
import '../responsive.css';
import DarkModeToggle from './DarkModeToggle';
import SearchBar from './SearchBar';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const menuItems = [
        { icon: '📚', label: 'Introduction générale', href: 'introduction' },
        { icon: '🏗️', label: 'Mise en place de l\'infrastructure', href: 'introduction-2' },
        { icon: '🔒', label: 'Sécurisation de l\'entreprise', href: 'security' },
        { icon: '👥', label: 'Gestion des utilisateurs et des ressources', href: 'user-management' },
        { icon: '⚠️', label: 'Évaluation des cyber-risques', href: 'risk-assessment' },
        { icon: '☁️', label: 'Outils déployés', href: 'tools' },
        { icon: '🎯', label: 'Conclusion', href: 'conclusion' }
    ];

    return (
        <>
            <button 
                className="md:hidden fixed top-4 right-4 z-50 bg-blue-600 hover:bg-blue-700 text-white p-2.5 rounded-lg shadow-lg transition-colors duration-200"
                onClick={toggleSidebar}
                aria-label="Toggle menu"
            >
                <svg 
                    className="w-6 h-6" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                >
                    {isOpen ? (
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M6 18L18 6M6 6l12 12"
                        />
                    ) : (
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    )}
                </svg>
            </button>

            <div className={`sidebar bg-white dark:bg-gray-900 ${isOpen ? 'open' : ''}`}>
                <div className="px-4 py-3 border-b dark:border-gray-700">
                    <h1 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Projet Tuteuré</h1>
                </div>
                
                <nav className="pb-32">
                    {menuItems.map((item, index) => (
                        <Link
                            key={index}
                            to={item.href}
                            smooth={true}
                            duration={500}
                            activeClass="active"
                            spy={true}
                            className="
                                flex items-center px-4 py-2.5
                                text-gray-700 dark:text-gray-300
                                hover:bg-gray-100 dark:hover:bg-gray-800
                                hover:text-white dark:hover:text-white
                                transition-colors duration-200
                            "
                        >
                            <span className="mr-3 text-xl">{item.icon}</span>
                            <span>{item.label}</span>
                        </Link>
                    ))}
                </nav>

                <div className="absolute bottom-0 left-0 right-0 bg-white dark:bg-gray-900 border-t dark:border-gray-700">
                    <div className="px-4 pt-3">
                        <SearchBar />
                    </div>
                    <div className="px-4 py-3 flex items-center justify-between">
                        <img 
                            src="https://charte.efrei.fr/wp-content/uploads/2022/07/LOGO_EFREI-WEB_noir.png" 
                            alt="Efrei Logo" 
                            className="w-20 dark:invert opacity-80 hover:opacity-100 transition-opacity duration-200"
                        />
                        <DarkModeToggle />
                    </div>
                </div>
            </div>

            {isOpen && (
                <div 
                    className="fixed inset-0 bg-black bg-opacity-50 md:hidden z-40"
                    onClick={toggleSidebar}
                />
            )}
        </>
    );
};

export default Sidebar;